.bell-menu-h4{
    font-size: 20px;
    font-weight: 700;
    padding: 15px 15px 0px 15px;
    letter-spacing: -0.5px;
}

.black-type-chip{
    padding: 2.5px 5px;
    background: #ff690f;
    border-radius: 7px;
    color: black;
    display: flex;
    justify-content: center;
    width: fit-content;
    font-size: 14px;
    letter-spacing: -0.5px;
    font-weight: 500;
}

.notification-count-marker{
    position: absolute;
    background-color: #ff690f;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 11px;
    top: 12px;
    right: 8px
}

.notification-container {
    scrollbar-width: none !important; /* For Firefox */
    -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
  }
  
  .notification-container::-webkit-scrollbar {
    display: none !important; /* For Chrome, Safari, and Edge */
  }

  .custom-input-container {
    width: calc(100% - 30px);

    margin: 5px 15px 5px 15px;
    height: 32px;
    background-color: #F0EDE4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  
  .custom-input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    color: #000;
  }
  
  .custom-input::placeholder {
    color: #999;
    font-weight: 700;
    font-size: 16px;
  }
  
  .custom-dropdown-filter{
    width: calc(100% - 30px);
    margin: 10px 15px 5px 15px;
    background: inherit;
    border: none !important;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  .custom-dropdown-filter:hover{
    border: none !important;
  }

  .custom-dropdown-filter:focus-visible{
    border: none !important;
    outline: none !important;
  }