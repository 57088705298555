.chatAiWrapper {
  display: flex;
  justify-content: center;
  align-items: end;
  // padding: 16px 24px;
  position: relative;
  // height: calc(100vh - 80px);
  .innerWrapper {
    max-width: 1024px;
    width: 95%;

    .contentWidth{
      width: 83% !important;
      margin: auto !important;
    }

    @media screen and (max-width:750px) {
      .contentWidth{width:100% !important; }     
    }


    .content {
      height: calc(100vh - 156px);
      // padding-bottom: 140px;
      overflow-y: auto;
      overflow-x: hidden;
      // max-width: 95%;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      .Question {
        display: flex;
        align-items: center;
        gap: 8px;
        border: 1px solid #E8E8E8;
        border-radius: 15px;


        .QuestionInnerContaienr {
          max-width: 660px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          flex: 1;
          margin: auto;
          padding: 24px 12px;
          gap: 16px;
        }

        .ImageWraper {
          max-height: 40px;
          max-width: 40px;
          border-radius: 6px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 6px;
          }
        }

        .ImageWraper_2 {
          min-width: 40px;
          min-height: 40px;
          display: flex;
          border-radius: 100%;
          color: white;
          background: #9696fa;
          justify-content: center;
          align-items: center;
          font-weight: 700
          img {
        
            object-fit: cover;
            border-radius: 6px;
          }
        }
        .contentx {
          // text-align: left;
          padding-top: 1px;
          .userName {
            color: var(--black);
            font-family: "Karla", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            // p {
            //   text-align: left;
            // }
          }
        }
      }

      @media screen and (max-width: 924px) {
        .ChatResponce{
          padding: 24px !important;
        } 
        
        .QuestionInnerContaienr{
          padding: 24px !important;
        }
      }
      .ChatResponce {
        display: flex;
        align-items: flex-start !important;
        gap: 16px;
        padding-bottom: 24px;
        border-radius: 8px;
        // background: var(--light-yellow, #FAF7EC);
        padding: 24px 12px;
        max-width: 660px;
        margin: auto;

        .ImageWraper {
          max-height: 40px;
          min-width: 40px;
          border-radius: 6px;

          img {
            // height: 100%;
            // width: 100%;
            object-fit: cover;
            border-radius: 6px;
          }
        }
        .contentx {
          padding-top: 1px;
          .userName {
            color: var(--black);
            font-family: "Karla", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            display: block;
          }
        }
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .noheight {
      height: auto;
    }
    .footer {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 1024px;
      position: absolute;
      width: auto;
      width: 100%;

      // border: 2px solid red;
      bottom: -24px;
      max-width: 1024px;
      
      .InputWrapper {
        position: relative;
        bottom: 0px;
        width: 80%;
        display: flex;
        justify-content: center;
        left: 0px;
      }
    }
  }
}

.ChatbotResponseContainer{
  border-radius: 15px !important;
  border: 1px solid red !important;
}

.ChatScreenFooterLeft{
  display: flex;
}

.ChatScreenFooterRight{
  display: flex;
}

@media screen and (max-width:750px) {
  .chatAiWrapper {
    display: flex;
    justify-content: center;
    align-items: end;
    // padding: 16px 24px;
    position: relative;
    // height: calc(100vh - 80px);
    .innerWrapper {
      max-width: 1024px;
      width: 100%;
      .content {
        height: calc(100vh - 156px);
        // padding-bottom: 140px;
        overflow: auto;
        width: 100%;
        margin: 2% 1% 0% 0%;
        .Question {
          display: flex;
          align-items: center;
          gap: 8px;
  
          .QuestionInnerContaienr {
            max-width: 660px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            // set values to center to vertically center align user profile image circle 
            // display: flex;
            // justify-content: center;
            // align-items: center;
            width: 100%;
            flex: 1;
            margin: auto;
            padding: 24px;
            gap: 16px;
          }
  
          .ImageWraper {
            max-height: 28px !important;
            max-width: 28px !important;
            border-radius: 6px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 6px;
            }
          }
  
          .ImageWraper_2 {
            min-width: 40px;
            min-height: 40px;
            font-size: small;
            display: flex;
            border-radius: 100%;
            color: white;
            background: #9696fa;
            justify-content: center;
            align-items: center;
            font-weight: 700
            img {
          
              object-fit: cover;
              border-radius: 6px;
            }
          }
          .contentx {
            // text-align: left;
            padding-top: 0px;
            .userName {
              color: var(--black);
              font-family: "Karla", sans-serif;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              p {
                text-align: left;
              }
            }
          }
        }
        .ChatResponce {
          display: flex;
          align-items: flex-start !important;
          gap: 24px; //was 16px
          padding-bottom: 24px;
          border-radius: 8px;
          // background: var(--light-yellow, #FAF7EC);
          padding: 24px;
          max-width: 660px;
          margin: auto;
  
          .ImageWraper {
            max-height: 28px;
            min-width: 28px;
            border-radius: 6px;
  
            img {
              // height: 100%;
              // width: 100%;
              object-fit: cover;
              border-radius: 6px;
            }
          }
          .contentx {
              padding-top : 0px;
            .userName {
              color: var(--black);
              font-family: "Karla", sans-serif;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
  
              display: block;
            }
          }
          p {
            padding: 0;
            margin: 0;
          }
        }
      }
      .noheight {
        height: auto;
      }
      .footer {
        align-items: center;
        display: flex;
        justify-content: center;
        max-width: 1024px;
        position: absolute;
        width: auto;
        width: 100%;
        // backdrop-filter: blur(10px);

          /* Add the blur effect */
  // filter: blur(8px);
  // -webkit-filter: blur(8px);

  
        // border: 2px solid red;
        // bottom: -24px;
        bottom: 20px; 
        max-width: 1024px;
        
        .InputWrapper {
          position: relative;
          bottom: 0px;
          width: 80%;
          display: flex;
          justify-content: center;
          left: 0px;
        }
      }
    }
  }
}

.ChatScreenFooter { 
  flex-wrap: wrap;
  display: flex;
  padding: 12px 0px;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // max-width: 636px;
  max-width: 540px;
  margin: auto;
  gap: 8px;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid var(--gray-500);
}

@media screen and (max-width: 860px) and (min-width: 751px) {
  .ChatScreenFooter{
    padding: 12px !important;
  }  
}

@media screen and (max-width:750px) {
  .ChatScreenFooter {
    display: flex;
    padding: 12px 6px;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 540px;
    width: 100%;
    margin: auto;
    gap: 8px;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid var(--gray-500);
  }  
}

.collapsbtn {
  color: var(--gray-800, #454b54);
  font-family: Karla;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
  cursor: pointer;

  & *::selection {
    color: currentColor;
    background-color: transparent;
  }

  .chevronDown {
    transform: rotate(180deg);
  }

  svg {
    margin-left: 8px;
  }
}

.collapsContent {
  border-left: 3px solid var(--mellow-blue-300-main, #9696fa);
  background: var(--mellow-blue-nav, #dcdff6);
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  p {
    color: var(--Black, #000);
    font-family: Karla;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.54px;
    margin: 0 !important;
    padding: 0 !important;
  }
}
// @keyframes blink {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// .blinkingcursor {
//   animation: blink 1s infinite;
// }
.blinkingcursorLTR {
  font-weight: bold;
  display: inline-block;
  // font-family: monospace;
  // font-size: 30px;
  clip-path: inset(0 1ch 0 0);
  animation: l 1s steps(4) infinite;
}

.blinkingcursorRTL {
  font-weight: bold;
  display: inline-block;
  // font-family: monospace;
  // font-size: 30px;
  clip-path: inset(0 0 0 1ch); // Adjusted to target the first three characters
  animation: l 1s steps(4) infinite;
}


@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}


.orangeBgBtnTransition {
  transition: all 0.3s ease;

  &:hover {
    background-color: #fe6100;  
    color: white;

    .orange-bg-btn-text {
      color: white;
    }
  }
}

.wand_icon_white{
  path{
    stroke: white !important;
  }
}