  .markdown-heading-custom {
    margin-top: 25px;
  }

  /* removes margin top from the 1st heading in markdown-content div*/
  .markdown-content > :first-child.markdown-heading-custom {
    margin-top: 0px !important;
  }

  .dJhprX {
    position: relative;
    background: #282a36;
    border-radius: 0.25rem;
    padding: 0.75rem !important;
  }
  
  
  .language-math,  .math-inline{
    color: black;
    font-size: 18px;
  }

  
  .markdown-container{
    width: 100%;
    max-width: 90% ;
  }
    
  .markdown-table{
    max-width: 100% !important;
    overflow-x: auto;
    overflow-y: hidden;
  }
   
  
  .markdown-content {
     /* Adjust line height for readability */
       line-height: 24px;
       width: 100%;
       max-width: 100%;
       /* padding-right: 12px; */
  
    /* Preserve whitespace and line breaks */
    /* white-space: pre-wrap;  */
  }
  
  .markdown-codeblock{
    margin: 0;
    white-space: nowrap;
  }
  
  /* .markdown-content li{
    margin-bottom: 10px;
  } */

  .alphabets-numbering{
    margin-left: 6%;
  }
  pre{
    white-space: pre-line !important;
  }