.InputWrapper1 {
    // max-width: 692px;
    // width: 100%;
    // position: fixed;
    // bottom: 0px;
    // width: 100%;
    // max-width: 692px;
    display: flex;
    justify-content: center;
    left: 0px;
    position: relative;
  
    // min-width: 692px;
    border-radius: 15px 15px 0px 0px !important;
    // margin-bottom: 42px;
    border: 1px solid #E8E8E8;
    background: var(--black-white-white, #fff);
    // box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px -10px 25px -10px rgba(0, 0, 0, 0.15);  /* Top shadow */
                //10px 0px 25px -10px rgba(0, 0, 0, 0.15);   /* Right shadow */
                //-10px 0px 25px -10px rgba(0, 0, 0, 0.15);  /* Left shadow */
    width: 100%;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 65px;
  
    .Input1 {
      border: none !important;
      // width: 80%;
      position: relative;
      // padding: 25px 0px 16px 36px !important;
      height: 76px !important;
      box-shadow: none;
      border-radius: 12px !important;
      color: #74787f !important;
      resize: none;
      &::placeholder {
        position: absolute;
        top: 50%;
        // width: 100%;
        transform: translateY(-50%);
        color: #999; /* Adjust the color as needed */
      }
      &:focus::placeholder {
        color: transparent;
      }
      &:focus {
        padding: 25px !important;
        height: 76px !important;
        border-radius: 12px !important;
        border: none !important;
        background: var(--black-white-white, #fff) !important;
        box-shadow: none !important;
      }
    }
    .ButtonInput1 {
      position: absolute;
      width: fit-content !important;
      top: 14px;
      right: 16px;
      padding: 0px !important;
      background-color: #8d8d8f;
      border-color: white;
  
      .circlePath:hover {
        fill: #9593FD; /* Change to your desired hover color */
        //transition: fill 0.3s ease; /* Optional: Add smooth transition */
      }
      
      &.active {
        //background-color: #9593FD !important;
        // border-color: #9593FD !important;

          fill: #9593FD; /* Change to your desired hover color */
          svg{
            path:nth-child(1) {
              fill: #9593FD !important; /* Change to your desired hover color */
            }
          }
      }
    }

    .RecordButton {
      position: absolute;
      width: fit-content !important;
      top: 15px;
      right: 70px;
      padding: 0px !important;
      background-color: #8d8d8f;
      border-color: white;
  
      // .circlePath:hover {
      //   fill: #9593FD; /* Change to your desired hover color */
      //   //transition: fill 0.3s ease; /* Optional: Add smooth transition */
      // }
      
      // &.active {
      //   //background-color: #9593FD !important;
      //   // border-color: #9593FD !important;

      //     fill: #9593FD; /* Change to your desired hover color */
      //     svg{
      //       path:nth-child(1) {
      //         fill: #9593FD !important; /* Change to your desired hover color */
      //       }
      //     }
      // }
    }

    .AttachmentBtn {
      position: absolute;
      width: fit-content !important;
      top: 14px;
      right: 72px;
      padding: 10px 12px !important;
    }
    .StopGeneratingBtn {
      position: absolute;
      width: fit-content !important;
      top: 16px;
      right: 16px;
      padding: 10px 12px !important;
      border: none;
      background-color: var(--white);
      color: #c8352f;
    }
  
    .StopGeneratingBtnAr {
      position: absolute;
      width: fit-content !important;
      top: 16px;
      left: 16px;
      padding: 10px 12px !important;
      border: none;
      background-color: var(--white);
      color: #c8352f;
    }
  }


