.prompt-plus-main{
    border: 1px solid #E8E8E8;
    box-shadow: 0px 18px 40px 0px #0000001A;
    /* box-shadow: 0px 290px 116px 0px #00000003; */
    /* box-shadow: 0px 454px 127px 0px #00000000; */
    border-radius: 0px 0px 15px 15px;
    min-height: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 660px;
    background: white ;

    margin-bottom: 30px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 660px;
}

.prompt-plus-and-mode-container{
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
}

.prompt-plus-option-container{
    display: flex;
    /* gap: 25%; */
    /* justify-content: space-between; */
    padding: 0px 15px;
}

.prompt-plus-icon-div{
    width: 25px;
}

@media screen and (max-width: 776px){
    .prompt-plus-option-container{
        gap: 0px;
        /* justify-content: space-between; */
    }

    .generate-prompt-button-custom-en{
        width: 100% !important;
        margin-left: 0px !important;
    }

    .generate-prompt-button-custom-ar{
        width: 100% !important;
        margin-right: 0px !important;
    }

    .prompt-plus-option-container p {
        font-size: 16px;
    }

    .prompt-plus-text-black{
        font-size: 16px;
        width: 100%;
    }

    .prompt-plus-text-grey{
        font-size: 16px;
        width: 100%
    }
}

.action-prompt-option-hover:hover{
    color: #898E89;
}

@media screen and (max-width: 430px){
    .body-row{
        flex-direction: column;
    }
    
    .body-column{
        width: 100% !important;
    }
    
    .prompt-plus-title{
        width: 100% !important;
    }

    .quick-start-label{
        width: 100% !important;
    }
}

.prompt-plus-title{
    display: flex;
    gap: 15px;
    padding-top: 15px;
    width: 50%;
}

.body-row{
    padding: 15px 15px;
    display: flex;
}

.body-column{
    width: 50%;
}

.action-prompt-option{
    display: flex;
    gap: 10px;
    height: 40px;
    width: 100%;
    /* padding-top: 15px; */
}

.suggest-prompt-option{
    height: fit-content;
    padding-top: 0px;
    line-height: 18px;
}

.suggested-prompts-container {
    max-height: 200px; 
    overflow-y: auto; 
    overflow-x: hidden; 
    width: 103%;        
}
.suggested-prompts-container::-webkit-scrollbar {
    width: 16px;
}
.suggested-prompts-container::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
}
.suggested-prompts-container::-webkit-scrollbar-thumb:hover {
    background-color: #898E89; 
}

.prompt-plus-chatmode{
    display: flex;
    margin-top: 15px;
    gap: 10px;
}

.prompt-plus-text-grey{
    color: #898E89;
    font-size: 18px;
    letter-spacing: -0.5px;
    font-weight: 500;
    width: 100%
}

.prompt-plus-text-black{
    color: #0D1E12;
    font-size: 18px;
    letter-spacing: -0.9px;
    font-weight: 500;
    width: 100%;
}

.div-hover-cursor:hover{
    cursor: pointer;
}

.custom-switch {
    outline: none !important;
    box-shadow: none !important;
    padding: 0px 10px;
    border-radius: 5px;
    margin-top: -5px;
  }

.custom-switch .form-check-input:not(:checked):focus{
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}

  .custom-switch .form-switch .form-check-input{
    color:white !important;
    background-color: white !important;
    width: 46px;
    height: 25px;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  
  .custom-switch .form-check-input {
    background-color: #D0CCBB !important;
    border-color: #D0CCBB !important;
  }
  
  .custom-switch .form-check-input:checked {
    background-color: #D0CCBB !important;
    border-color: #D0CCBB !important;
  }

  .form-control:focus{
    box-shadow: none;
  }
  
  .custom-switch .form-check-input:checked::before,
  .custom-switch .form-check-input::before {
    background-color: white !important;
    border-color: #D0CCBB !important;
    box-shadow: none !important;
  }
  
  .custom-switch .form-check-input:active,
  .custom-switch .form-check-input:active:focus {
    outline: none !important;
    box-shadow: none !important;
  }

.pseudo-accordion-button{
    width: 100%;
    background: #F3F4F3;
    justify-content: space-between;
    padding: 0px 15px;
    align-items: center;
    display: flex;
    height: 44px;
}

.pseudo-accordion-button-left{
    width: 50%;
    background: #F3F4F3;
    padding: 0px 15px;
    align-items: center;
    display: flex;
    height: 44px;
}
.pseudo-accordion-body{
    background-color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.pseudo-accordion-form-body{
    padding: 30px;
}

.action-option-body-heading{
font-size: 18px;
font-weight: 700;
line-height: 19.2px;
letter-spacing: -0.5px;
color: #0D1E12;
}

.action-option-body-field-label{
    color: #898E89;
    font-size: 18px;
    font-weight: 700;
    line-height: 19.2px;
    letter-spacing: -0.5px;
    display: contents;
}

.field-container{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 15px;
}

.action-field-label-container{
    /* display: contents; */
    height: 100%;
    width: 100px;
}

.aim-field .form-select{
    width: 200px;
    height: 35px;
    padding: 5px 10px !important;
    line-height: 25px !important;
    background-color: #f3f4f3 !important;
    direction: ltr !important;
    
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.01em;
}

.textbox-field{
    width: 80%
}

.textbox-field .form-control {
    background-color: #f3f4f3;
    height: 68px;
    width: 100%; 
    padding: 10px !important;
    font-size: 16px; 
    border-radius: 5px; 
    box-shadow: none; /* Optional: to remove any default shadow */
}

.text-field .form-control{
    background-color: #f3f4f3 !important;
    height: 35px !important;
    width: 100%; 
    padding: 10px !important;
}

.generate-prompt-button-custom-en{
    width: 80%;
    height: 43px;
    border-radius: 5px;    
    color: white;
    background: #9593FD;
    margin-left: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.generate-prompt-button-custom-ar{
    width: 80%;
    height: 43px;
    border-radius: 5px;    
    color: white;
    background: #9593FD;
    margin-right: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.complete-profile-button-custom-en{
    width: 195px;
    height: 25px;
    border-radius: 25px;    
    color: white;
    background: #9593FD;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.complete-profile-button-custom-ar{
    width: 195px;
    height: 33px;
    border-radius: 20px;    
    color: white;
    background: #9593FD;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.generate-prompt-button-text{
    color: #FFFFFF;
    font-size: 18px;
    letter-spacing: -0.5px;
    font-weight: 500;
    display: contents;
}

.complete-profile-text{
    font-size: 18px;
    letter-spacing: -0.5px;
    font-weight: 500;
    display: contents;
}


@media (max-width: 940px) {

    .to-from-container-desktop {
        display: none;
    }

    .to-from-container-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .to-from-field {
        display: flex;
        width: 100%;
        margin-bottom: -5px;
        flex-direction: row;
    }

    .to-from-input {
        width: 100%;
    }

    .label-container{
        width: 145px !important;
    }

    .quick-ui-dropdown-container{
        display: flex !important;
        flex-direction: column !important;
        gap: 10px !important;
    }

    .quick-setup-ui-row {
        gap: 25px;
        margin-top: -10px;
        padding: 15px 20px;
        margin-bottom: 15px;
        align-items: baseline !important;
    }

    .quick-setup-ui-select-dropdown{
        width: 100% !important;
    }

    .quick-setup-ui-accordion-header-text{
        width: 80% !important;
      }

}
@media (min-width: 941px) {
    .to-from-container-mobile {
        display: none;
    }
}

.centralize-suggestion-prompts-sub-loader{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
  }
  .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Adjust the width as needed */
}


.quick-ui-dropdown-container{
    display: flex !important;
    gap: 25px;
  }

  .quick-setup-ui-row {
    gap: 25px;
    margin-top: -10px;
    padding: 15px 20px;
    margin-bottom: 15px;
    align-items: center;
  }

  .quick-setup-ui-select-dropdown{
    text-overflow: ellipsis;
  }


