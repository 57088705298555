
.more-uses-btn-text{
    font-size: large;
    color: #ff5b00;
    text-align: center !important;
    font-weight: 500 !important;
    letter-spacing: -0.5px;
    /* padding-top: 5%; */
  
  }
  
  @media screen and (max-width: 400px){
    .more-uses-btn-text{
      letter-spacing: -0.5px;
      font-size: 15px !important;
    }
  }
  
  @media screen and (min-width:400px) and (max-width: 430px){
    .more-uses-btn-text{
      font-size: 18px !important;
      letter-spacing: -0.5px;

    }
  }
  
  @media screen and (max-width: 430px) {
  
    .more-uses-heading{
      font-size: x-large !important;
    }
  
    .more-uses-dialog-btn{
      width: 85% !important;
    }
  }
  
  .more-uses-heading{
    font-size: xx-large;
  }
  
  .more-uses-dialog-btn{
    width:max-content;
    padding: 1%;
  }
  
  .more-uses-btn {
    cursor: pointer;
    border: 2px solid #ff5b00;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
  .close-circle{
    width: 30px;
    height: 30px;
    border: 1.2px solid black;
    border-radius: 50%;  
  }

  .timer-box{
    display: flex;
    width: 100%;
    height: 76px;
    justify-content: baseline;
    align-items: center;
    padding-left: 30px;
  }

  .timer-box div p{
    margin-bottom: 0px !important;
    font-weight: 500;
    color: #898E89;
    line-height: 19.2px;
    font-size: 18px;
  }

  .generic-grey-text{
    margin-bottom: 0px !important;
    font-weight: 500;
    color: #898E89;
    line-height: 19.2px;
    font-size: 18px;
    letter-spacing: -0.5px;
  }