.process-table-h4 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.5px;
  color: grey;
  padding-top: 2.5px;
}

.prop-val-p {
  color: black;
  font-weight: 500;
  font-size: 16px;
  color: #6c736c;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 2px 2px;
}

.custom-invoice-canvas-menu div ul li{
    font-weight: 600;
    letter-spacing: -0.5px;
}

.skeleton-invoice {
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, #FFFFFF 20%, #d0d4e2 50%, #FFFFFF 80%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 15px; /* Optional, for rounded corners */
    height: 265.5px;
    margin-bottom: 8px;
    border: 1px solid #E8E8E8;
  
  }
  
  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
