.invoice-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: calc(100vh - 132px);
  font-family: "Karla", sans-serif;
  overflow-y: hidden;
  height: max-content; // set to hidden since it renders an extra scroller in analyse module
  // height: 100%;
  .invoice-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 540px;
    gap: 6px;

    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        color: var(--gray-900);
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 41px; /* 128.125% */
        letter-spacing: -1.28px;
      }
      span {
        color: var(--gray-800);
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.64px;
      }
    }
    .upload-wrapper {
      position: relative;
      border-radius: 20px;
      border: 2px dashed var(--gray-500);
      background: var(--white);
      padding: 45px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      overflow: hidden;

      circle {
        fill: #cccccc;
      }

      // fixes icon from becoming enlarged due to wider width
      @media screen and (min-width: 900px) {
        svg {
          height: 80px;

          // path,
          // circle {
          //   stroke: var(--grey-main);
          // }
        }
      }

      // &:hover {

      //   background-color: var(--bright-teal-100);
      //   border-color: #198754;

      //   // parts/path elements of SVG logo
      //   path:last-child,
      //   path:nth-last-child(3) {
      //     fill: white !important;
      //     stroke: white !important;
      //   }
      //   path:nth-last-child(2) {
      //     fill: white !important;
      //     stroke: white !important;
      //   }
      // }

      &-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        max-width: 279px;
        width: 100%;
        text-align: center;
        letter-spacing: -0.56px;
        &-browser {
          color: var(--grey-main);
          display: block;
          text-align: center;
        }
        &-dragDrop {
          display: block;
          color: var(--grey-main);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.56px;
          text-align: center;
        }
      }
      .file-Input {
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }

    // .upload-wrapper:hover {
    //    background-color: var(--bright-teal-100);
    //   border-color: #198754;
    // }
    .file_uploaded_wrapper {
      &:hover {
        background-color: var(--bright-teal-100) !important;
      }
    }
  }

  .invoice-module-container {
    // gap: 102px !important;
    gap: 5px !important;
  }

  @media screen and (max-width: 750px) {
    .invoice-container {
      padding-left: 0%;
    }
  }
}
.disabledfield {
  pointer-events: none; /* Example: Disabling pointer events */
}
