.centered-box{
    display: flex;
    width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
}

.reactour__popover {
    border-radius: 30px !important;
    background: #FF690F !important;
    color: white !important;
    padding: 10px 23px !important; /* Simplified padding */
    /* max-width: 390px !important; */
    max-width: max-content !important;
    text-align: left; /* Align text naturally */
    word-wrap: break-word; /* Prevent overflow of long words */
    word-break: break-word; /* Handle unbreakable text */
    line-height: 1.5; /* Improve readability */
}
.reactour__mask{
    color: rgba(217, 217, 217, 0.8) !important; 
  opacity: 1;
  border: none !important; /* Removes the border */
    box-shadow: none !important; /* Removes any shadow */
    background-color: transparent !important; /* Makes the background fully transparent */
}

.StartTour:hover{
    background:white !important;
    color: #fe6100 !important;
    border: 1px solid #fe6100 !important;
}
.SkipTour:hover{
    background:white !important;
    color: #fe6100 !important;
    border: 1px solid #fe6100 !important;   
}



.reactour__popover >span {
    display:none !important
}

.reactour__popover >button {
    display:none !important
}

.reactour__popover > div {
    /* display:none !important */
}

.profile-view-menu-en{
    margin-left:  -51px !important;
}

.react-pdf__Document{
    overflow: auto !important;
  }

/* .react-pdf__Page__canvas, .react-pdf__Page__textContent, .react-pdf__Page__annotations{
    max-width: 100% !important;
} */

