.custom-input-container-analyse {
  width: calc(100%);
  height: 32px;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #e8e8e8;
}

.custom-input-analyse {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #000;
}

.custom-input-analyse::placeholder {
  color: #999;
  font-weight: 500;
  font-size: 16px;
}

.custom-dropdown-filter {
  width: calc(100%);
  margin: 10px 15px 5px 15px;
  background: inherit;
  border: none !important;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.custom-dropdown-filter:hover {
  border: none !important;
}

.custom-dropdown-filter:focus-visible {
  border: none !important;
  outline: none !important;
}

@media screen and (max-width: 900px) {
  .file-ul-responsive-size {
    width: 100%;
    margin: 0px;
  }
}

@media screen and (min-width: 900px) {
  .file-ul-responsive-size {
    width: calc(90.5%);
    margin: 0px 4.75%;
  }
}
